@import 'descriptions';

.withdraw {
  text-align: center;
}

.currencyDropdown {
  margin-inline: auto;
  margin-bottom: 32px;

  @media @s-tablet {
    margin-bottom: 24px;
  }
}

.form {
  .f-column();
  gap: 24px;

  @media @s-tablet {
    gap: 12px;
  }
}

.amountUsdContainer {
  position: relative;
}

.spinner {
  position: absolute;
  top: 15px;
  right: 16px;
  pointer-events: none;
}

.input {
  text-align: left;
}

.description {
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 12px;

  @media @s-tablet {
    display: none;
  }
}

.tooltipContainer {
  display: flex;
  align-items: center;
}

.tooltip {
  margin-left: 0.3em;
}

.db {
  display: block;
}

.text {
  @media @s-tablet {
    display: none;
  }
}

.btn {
  max-width: 492px;
  margin-inline: auto;
}

.mobileDescription {
  text-align: left;
  display: none;

  @media @s-tablet {
    display: block;
  }
}

.mobileDescriptionItem {
  .f-between();

  & + & {
    margin-top: 8px;
  }
}

.mobileTooltip {
  margin-inline: 0.3em auto;
}

.mobileText {
  padding-top: 12px;
  display: none;

  @media @s-tablet {
    display: block;
  }
}

.playBtn {
  margin-top: 32px;

  @media @s-tablet {
    margin-top: 24px;
  }
}

.globalSpinner {
  z-index: 1;
}
