@import 'descriptions';

.minAmount {
  .transition(color);

  &.animated {
    animation: amount 3s linear;
  }
}

@keyframes amount {
  0% {
    color: inherit;
  }

  25% {
    color: @primary-color;
  }

  50% {
    color: inherit;
  }

  75% {
    color: @primary-color;
  }

  100% {
    color: inherit;
  }
}
