@import 'descriptions';

.creditAmount {
  text-align: center;
}

.instruction {
  margin-bottom: 32px;

  @media @s-tablet {
    margin-bottom: 16px;
  }
}

.currencyDropdown {
  margin-inline: auto;
  margin-bottom: 32px;

  @media @s-tablet {
    margin-bottom: 16px;
  }
}

.text {
  max-width: 406px;
  margin-inline: auto;
  margin-bottom: 32px;

  @media @s-tablet {
    line-height: 18px;
    margin-bottom: 24px;
  }
}

.field {
  text-align: left;
  max-width: 422px;
  margin-inline: auto;
}

.btn {
  max-width: 300px;
  margin-top: 32px;

  @media @s-tablet {
    margin-top: 24px;
  }
}

.spinner {
  z-index: 1;
}
