@import 'descriptions';

.demoWinDialog {
  .f-column();
  text-align: center;
  justify-content: center;
  height: 100%;
}

.content {
  margin-top: auto;
}

.logo {
  width: 108px;
  height: 78px;
  margin-bottom: 24px;
}

.title {
  margin-bottom: 12px;
}

.buttons {
  .f-column();
  gap: 16px;
  margin-top: 32px;

  @media @s-tablet {
    margin-top: auto;
  }
}
