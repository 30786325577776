@import 'descriptions';

.support {
  .hover(#666, @white);
  text-align: center;
  padding-bottom: 28px;
}

.title {
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  margin-inline: auto;
  margin-bottom: 4px;
}
