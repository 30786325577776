@import 'descriptions';
@import 'animations/smooth-show';

.copiedSuccess {
  .radius(32);
  .offsetX();
  background-color: @blocks-bg;
  border: 1px solid @primary-color;
  box-shadow: 0 0 5px @primary-color;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  bottom: 58px;
  z-index: @z-index-fixed;
  width: 100vw;
  height: 84px;
  max-width: 662px;
  display: none;

  @media @s-tablet {
    .radius(16);
    bottom: 16px;
    max-width: calc(100% - @mobile-padding * 2);
    height: 52px;
  }

  &.shown {
    .smooth-show(@display: flex);
  }
}
