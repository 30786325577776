@import 'descriptions';

.title {
  margin-bottom: 20px;
}

.form,
.row {
  .f-column();
}

.form {
  gap: 20px;
}

.row {
  gap: 12px;
}

.description {
  line-height: 20px;
  margin-bottom: 8px;
}

.text {
  line-height: 20px;
}

.phraseField {
  position: relative;
  flex: 1;
}

.phraseInput {
  .ellipsis();
  padding-right: 16px * 2 + 24px;
}

.copyBtn {
  .hover(@gray-blue, @white);
  position: absolute;
  top: 0;
  right: 0;
  height: @fields-height;
  padding-inline: 16px;
}

.updatePhraseRow {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.buttons {
  .f-column();
  gap: 8px;
  margin-top: 32px;
}
