@import 'descriptions';

.button {
  .radius(@buttons-height);
  .PTRoot();
  .transition(background-color);
  .transition(border-color);
  .transition(box-shadow);
  .transition(color);
  color: @white;
  font-size: @s-font-size;
  display: inline-block;
  border: 1px solid transparent;
  white-space: nowrap;
  text-transform: uppercase;

  &.disabled {
    cursor: not-allowed;
  }
}

.content {
  .f-center();
  .radius(@buttons-height);
  overflow: hidden;
  position: relative;
  width: 100%;
  height: @buttons-height;
  padding-inline: 24px;
}

.primary {
  background-color: @primary-color;
  box-shadow: 0 2px 20px rgba(203, 0, 207, 0.30);
  font-weight: 800;

  .media-hover({
    background-color: @primary-hover;
  });

  .media-support-hover({
    &:active {
      background-color: @primary-press;
      box-shadow: none;
    }
  });

  &.disabled {
    background-color: @primary-disabled;
    box-shadow: none;
  }
}

.secondary {
  .media-hover({
    color: @gray-blue;
    border-color: @gray-blue-2;
  });

  .media-support-hover({
    &:active {
      color: @white;
      border-color: @white;
    }
  });

  &.disabled {
    color: fade(@white, 50%);
  }
}

.secondaryModal {
  color: @black;

  &.disabled {
    color: fade(@black, 50%);
  }
}

.secondary,
.secondaryModal {
  border-color: @gray-blue;
  font-weight: 600;

  &.disabled {
    border-color: fade(@gray-blue-2, 50%);
  }
}

.action {
  background-color: @dark-green;
  font-weight: 800;
  border: none;

  .media-hover({
    .content::after {
      opacity: 0;
    }
  });

  .content {
    z-index: 1;

    &::after {
      .abs-overlay();
      .transition(opacity);
      background: linear-gradient(180deg, @green 0, @medium-green 100%);
      content: '';
      z-index: -1;
    }
  }
}

.fluid {
  width: 100%;
}
