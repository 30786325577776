@import 'descriptions';

.innerPage {
  display: flex;
  gap: 16px;
}

.backLink {
  width: 169px;

  @media @s-tablet {
    display: none;
  }
}

.content {
  width: 100%;
  max-width: 662px;

  @media @s-tablet {
    .f-column();
    max-width: initial;
    min-height: calc(100dvh - @mobile-bottom-padding);
    padding-top: 16px;
  }
}
