@import 'descriptions';

.header {
  margin-bottom: 32px;

  @media @s-tablet {
    margin-bottom: 24px;
  }
}

.spinnerContainer {
  display: flex;
}

.row {
  & + & {
    border-top: 1px solid @gray-blue-2;
    padding-top: 16px;
    margin-top: 20px;
  }
}

.label {
  margin-bottom: 8px;

  @media @s-tablet {
    color: fade(@white, 50%);
  }
}

.password {
  .f-between();
  height: 24px;
}

.editBtn {
  .hover(@gray-blue, @white);
}

.iconEdit {
  @media @s-tablet {
    .square(20);
  }
}

.form {
  .f-column();
  gap: 12px;
  margin-top: 20px;
}

.logoutBtn {
  @media @s-tablet {
    color: @gray-blue;
    font-size: 0;
    border-color: transparent;
  }
}

.iconLogout {
  display: none;

  @media @s-tablet {
    display: block;
  }
}
