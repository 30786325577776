@import 'descriptions';
@import 'fonts';
@import 'reset';

html,
body {
  background-color: @background-color;
  min-height: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  .PTRoot();
  .base-text();
  color: @text-color;
  font-variant-numeric: lining-nums proportional-nums;
  -webkit-font-smoothing: antialiased;

  &.body-lock {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}
