@import 'descriptions';

.authTabs {
  margin-bottom: 16px;
}

.form {
  .f-column();
  gap: 16px;
  margin-top: 32px;
}

.recoveryRow {
  text-align: right;
}

.recoveryLink {
  .hover(fade(@white, 60%), @white);
  text-decoration: underline;
}
