@import 'descriptions';

.title {
  margin-bottom: 20px;
}

.form {
  .f-column();
  gap: 12px;
}

.buttons {
  .f-column();
  gap: 8px;
  margin-top: 32px;
}
