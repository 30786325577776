@import 'descriptions';

.rows {
  .f-column();
  gap: 8px;

  @media @s-tablet {
    .xs-text();
    flex-direction: row;
    gap: 4px;
    padding-bottom: 4px;
  }
}

.row {
  .f-between();

  @media @s-tablet {
    .radius();
    color: @gray-blue;
    background-color: @blocks-bg;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 8px;
    flex: 1;
    padding: 2px;
  }
}

.value {
  .radius();
  .f-center();
  background-color: @addition-bg;
  width: 72px;
  height: 32px;

  @media @s-tablet {
    color: @white;
    width: 46px;
    height: 24px;
  }
}

.buttons {
  display: flex;
  gap: 8px
}

.playBtn {
  margin-top: 24px;

  @media @s-tablet {
    margin-top: 0;
  }
}

.randomBtn {
  display: none;

  @media @s-tablet {
    display: inline-flex;
  }
}

.buttonFinishDemoGame {
  display: none;

  @media @s-tablet {
    display: flex;
  }
}

