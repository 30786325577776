@import 'descriptions';

.valueRow {
  & + & {
    margin-top: 12px;
  }
}

.label {
  opacity: 0.5;
  margin-bottom: 4px;
}

.number {
  line-height: 20px;
}
