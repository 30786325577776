@import 'descriptions';

.timer {
  .radius();
  background-color: @addition-bg;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  padding-inline: 12px;

  @media @s-tablet {
    justify-content: space-between;
    width: 100%;
  }

  @media @s-mobile {
    .radius(8);
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding-block: 8px;
  }
}

.time {
  display: flex;
}

.iconClock {
  opacity: 0.3;
  margin-left: 4px;
}
