@import 'descriptions';

.input {
  .radius(12);
  .transition(border-color);
  background-color: transparent;
  font-size: @s-font-size;
  color: @white;
  border: 1px solid @gray-blue-2;
  font-weight: 600;
  width: 100%;
  height: @fields-height;
  padding-inline: 16px;

  @media @s-tablet {
    font-size: @font-size;
  }

  &:hover {
    border-color: @gray-blue;
  }

  &:focus {
    border-color: @primary-color;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      border-color: @gray-blue-2;
    }
  }

  &::placeholder {
    color: fade(@white, 30%);

    .modal& {
      color: fade(@black, 30%);
    }
  }

  &.error {
    border-color: @red;
  }

  &.modal {
    color: @black;
  }
}

.errorMessage {
  margin-top: 4px;
}

