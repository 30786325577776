@import 'descriptions';

.square(@num) {
  width: @num + 0px;
  height: @num + 0px;
}

.circle(@num) {
  .square(@num);
  border-radius: 50%;
}

.f-center(@display: flex) {
  display: @display;
  align-items: center;
  justify-content: center;
}

.f-between(@align: center) {
  display: flex;
  align-items: @align;
  justify-content: space-between;
}

.f-column() {
  display: flex;
  flex-direction: column;
}

.radius(@r: @base-radius) {
  border-radius: @r + 0px;
}

.top-radius(@r: @base-radius) {
  border-radius: @r + 0px @r + 0px 0 0;
}

.bottom-radius(@r: @base-radius) {
  border-radius: 0 0 @r + 0px @r + 0px;
}

.left-radius(@r: @base-radius) {
  border-radius: @r + 0px 0 0 @r + 0px;
}

.right-radius(@r: @base-radius) {
  border-radius: 0 @r + 0px @r + 0px 0;
}

.shadow() {
  box-shadow: 0 4px 16px fade(@black, 16%);
}

.media-support-hover(@content) {
  @media @large-then-mobile and (hover: hover) and (pointer: fine) {
    @content();
  }
}

.media-hover(@content) {
  .media-support-hover({
    &:hover {
      @content();
    }
  });
}

.hover(@start-color, @end-color) {
  .transition(color);
  color: @start-color;

  .media-hover({
    color: @end-color;
  })
}

.transition(@prop: all, @duration: .2s, @function: linear, @delay: 0s) {
  transition+: @arguments;
}

.offset(@x: -50%, @y: -50%) {
  transform+_: translate(@x, @y);
}

.offsetX(@x: -50%) {
  transform+_: translateX(@x);
}

.offsetY(@y: -50%) {
  transform+_: translateY(@y);
}

.abs-overlay(@position: absolute) {
  position: @position;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.abs-alignment() {
  .offset();
  position: absolute;
  top: 50%;
  left: 50%;
}

.absY-alignment(@y: 50%) {
  .offsetY();
  position: absolute;
  top: @y;
}

.absX-alignment(@x: 50%) {
  .offsetX();
  position: absolute;
  left: @x;
}

.scale(@scale) {
  transform+_: scale(@scale);
}

.rotate(@angle) {
  transform+_: rotate(@angle + 0deg);
}

.flipX() {
  .scale(~'-1, 1');
}

.flipY() {
  .scale(~'1, -1');
}

.ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.scroll-touch(@axis: y) {
  -webkit-overflow-scrolling: touch;

  & when (@axis = x) {
    overflow-x: auto;
  }

  & when (@axis = y) {
    overflow-y: auto;
  }

  & when (@axis = auto) {
    overflow: auto;
  }
}

.hide-scrollbar() {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.aspect-ratio(@width, @height) {
  @supports (aspect-ratio: 1) {
    aspect-ratio: ~'@{width} / @{height}';
  }

  @supports not (aspect-ratio: 1) {
    padding-top: (@height / @width) * 100%;
  }
}

.linear-gradient-text(@bg) {
  background: @bg;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.cover-img(@width: 100%, @height: 100%) {
  object-fit: cover;
  width: @width;
  height: @height;
}

.triangle(@direction, @width, @height, @color: @white) {
  content: '';
  position: absolute;
  border: solid transparent;

  & when (@direction = 'top') {
    border-width: 0 (@width / 2 + 0px) (@height + 0px);
    border-bottom-color: @color;
  }

  & when (@direction = 'right') {
    border-width: @height / 2 + 0px 0 @height / 2 + 0px @width + 0px;
    border-left-color: @color;
  }

  & when (@direction = 'bottom') {
    border-width: @height + 0px @width / 2 + 0px 0;
    border-top-color: @color;
  }

  & when (@direction = 'left') {
    border-width: @height / 2 + 0px @width + 0px @height / 2 + 0px 0;
    border-right-color: @color;
  }
}

.hidden-field() {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.button-reset() {
  .PTRoot();
  font-size: @font-size;
  font-weight: normal;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: inherit;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  background: transparent;
  user-select: none;
  text-align: center;
  padding: 0;
}

.blur(@r: 0, @mode: filter) {
  & when (@mode = filter) {
    filter: blur(@r + 0px);
  }

  & when (@mode = back) {
    backdrop-filter: blur(@r + 0px);
  }
}

.smooth-show(@t: 0.2s, @display: block) {
  animation: smooth-show @t linear forwards;
  display: @display;
}
