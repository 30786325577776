@import 'descriptions';

.hidden {
  display: none;
}

.content {
  .radius();
  .scroll-touch();
  .hide-scrollbar();
  background-color: @blocks-bg;
  position: relative;
  max-height: 94vh;
  padding: 52px 64px 27px;

  @media @s-tablet {
    .radius(0);
    background-color: transparent;
    width: 100%;
    height: 100dvh;
    max-height: initial;
    padding-inline: @mobile-padding;
  }
}

.btn {
  .transition(color);
  position: absolute;
  top: 24px;
  right: 24px;

  .media-hover({
    color: @primary-hover;
  });
}
