@import 'descriptions';
@import 'animations/smooth-show';

.tooltip {
  .transition(color);
  position: relative;

  &:hover {
    color: @white;
  }
}

.tooltipContent {
  .radius(8);
  .absX-alignment();
  background-color: @background-color;
  padding: 8px;
  margin-top: 8px;
  display: none;

  &::before {
    .absX-alignment();
    .triangle('top', 6, 8, @background-color);
    bottom: 100%;
  }

  .tooltip:hover & {
    .smooth-show();
  }
}
