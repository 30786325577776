@import 'descriptions';

.spinnerContainer {
  display: flex;
}

.pagination {
  padding-top: 16px;
}

.win {
  color: @light-green;
}

.created {
  color: @orange;
}

.loss,
.cancelled {
  color: @red-light;
}
