@import 'descriptions';
@import 'animations/smooth-show';

.btn {
  .transition(background-color);
  .radius(8);
  display: flex;
  align-items: center;
  text-align: left;
  width: calc(100% + 12px);
  padding: 4px 6px;
  margin: -4px -6px;

  .media-hover({
    background-color: fade(@white, 5%);
  })
}

.title {
  flex: 1;
  min-width: 0;
}

.iconArrowAccordion {
  .rotate(-180);
  .transition(transform);
  .transition(color);
  color: @gray-blue;
  margin-left: 16px;

  .isOpened & {
    .rotate(0);
  }

  .media-support-hover({
    .btn:hover & {
      color: @white;
    }
  })
}

.content {
  padding-top: 24px;
  display: none;

  .opened & {
    .smooth-show();
  }
}

