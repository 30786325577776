@import 'descriptions';

.buttonRefresh {
  .hover(@gray-blue, @white);
}

.iconRefresh {
  .transition(transform);
  pointer-events: none;

  @media @s-tablet {
    .square(20);
  }

  .media-support-hover({
    .buttonRefresh:hover & {
      .rotate(180);
    }
  });
}
