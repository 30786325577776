@import 'descriptions';

.container {
  [data-rsbs-overlay],
  [data-rsbs-backdrop],
  [data-rsbs-root]::after {
    z-index: @z-index-modal;
  }

  [data-rsbs-overlay] {
    background: inherit;
  }

  [data-rsbs-backdrop] {
    .blur(8, back);
  }

  [data-rsbs-header] {
    box-shadow: none;
    padding: 0;

    &::before {
      .offsetX();
      background-color: @white;
      width: 40px;
    }
  }

  [data-rsbs-content] {
    .top-radius(20);
    background-color: @white;
    color: @dark-text-color;
    padding: 24px @mobile-padding 32px;
  }
}

.swipeBtn {
  display: block;
  height: 24px;
  width: 100%;
}
