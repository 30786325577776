@import 'descriptions';

.amountToPay {
  .radius(12);
  .f-between();
  .transition(background-color);
  background-color: @addition-bg;
  border: 1px solid @gray-blue-2;
  height: 60px;
  padding-inline: 16px;

  .media-hover({
    background-color: @blocks-bg;
  });
}

.value {
  display: flex;
  align-items: center;
  gap: 12px;
}
