@import 'descriptions';

.dialogPayment {
  width: 100%;
  max-width: 662px;
  padding: 32px;

  @media @s-tablet {
    background-color: @blocks-bg;
    padding: 16px;
  }
}

.btnClose {
  top: 16px;
}

.header {
  .f-between();
  margin-bottom: 16px;

  @media @s-tablet {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 12px;
  }
}

.successText {
  .linear-gradient-text(@gold-gradient);
  text-align: center;
  line-height: 20px;
  margin-bottom: 16px;

  @media @s-tablet {
    margin-bottom: 12px;
  }
}

.instruction {
  text-align: center;
  margin-bottom: 24px;

  @media @s-tablet {
    margin-block: 24px 0;
  }
}

.field {
  position: relative;
}

.input {
  background-color: @black;
  padding-right: 142px;
}

.label {
  display: block;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 16px;

  @media @s-tablet {
    margin-bottom: 12px;
  }
}

.logos {
  .absY-alignment();
  right: 16px;
  display: flex;
  gap: 12px;
  pointer-events: none;
}

.img {
  @media @s-tablet {
    width: auto;
    height: 17px;
  }
}

.transferInfo {
  margin-top: 24px;
}

.buttons {
  display: flex;
  gap: 16px;
  margin-top: 32px;

  @media @s-tablet {
    flex-direction: column-reverse;
    gap: 12px;
    margin-top: 24px;
  }
}
