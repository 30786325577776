@import 'descriptions';

.modal {
  @media @s-tablet {
    background: @blocks-bg;
    padding-top: 24px;
  }
}

.closeBtn {
  @media @s-tablet {
    top: 24px;
  }
}

.rulesDialog {
  max-width: 620px;
}

.logo {
  width: 108px;
  height: 78px;
  margin-bottom: 24px;

  @media @s-tablet {
    display: none;
  }
}

.title {
  margin-bottom: 16px;

  @media @s-tablet {
    text-transform: none;
    font-weight: 600;
    margin-bottom: 8px;
  }
}

.section {
  & + & {
    margin-top: 32px;
  }
}

.subtitle {
  margin-bottom: 16px;

  @media @s-tablet {
    margin-bottom: 8px;
  }
}

.mainText {
  @media @s-tablet {
    line-height: 20px;
  }
}

.text {
  color: fade(@white, 70%);

  @media @s-tablet {
    line-height: 20px;
  }

  & + & {
    margin-top: 16px;
  }
}

.img {
  .radius(12);
  margin-top: 16px;

  & + .text {
    margin-top: 16px;
  }
}

.btn {
  display: flex;
  margin-top: 40px;
  margin-inline: auto;

  @media @s-tablet {
    width: 100%;
  }
}
