@import 'descriptions';

.gameHeader {
  .radius();
  background: @addition-bg-gradient;
  position: relative;
  overflow: hidden;
  min-height: 128px;
  padding: 24px 36px;

  @media @s-tablet {
    .bottom-radius();
    padding: 16px @mobile-padding;
  }
}

.spinner {
  z-index: 1;
}

.mobileHeader {
  margin-bottom: 8px;
  display: none;

  @media @s-tablet {
    .f-between();
  }
}

.logo {
  width: 45px;
  height: 32px;
}

.mobileBalanceBtn {
  .radius();
  background-color: @blocks-bg;
  align-items: center;
  height: 32px;
  padding-inline: 8px;
  display: none;

  @media @s-tablet {
    display: flex;
  }
}

.iconUserCircle {
  color: @gray-blue;
  margin-right: 8px;
}

.titleContainer {
  .f-between(flex-start);
}

.buttonRefresh {
  .offsetY(6px);
}

.title {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;

  @media @s-tablet {
    font-size: @font-size-h3;
    line-height: 32px;
    margin-bottom: 4px;
  }
}

.uniqueCode {
  @media @s-tablet {
    color: @gray-blue;
    font-weight: 500;
  }
}

.code {
  margin-top: 4px;
}
