@import 'descriptions';
@import 'animations/rotation';
@import 'animations/smooth-show';

.container {
  .smooth-show();

  &.absolute {
    .f-center();
    .abs-overlay();
  }

  &.centered {
    margin: auto;
  }

  &.blur {
    .blur(8, back);

    &::before {
      .abs-overlay();
    }
  }
}

.primary {
  color: @primary-color;
}

.spinner {
  animation: rotation 1.3s infinite linear;
}
