@import 'descriptions';

.backLink {
  .radius();
  .transition(background-color);
  color: @gray-blue;
  display: flex;
  align-items: flex-start;
  padding: 18px 16px 16px;
  margin-top: -16px;
  margin-left: -16px;

  @media @s-tablet {
    font-size: 0;
    padding: 0;
    margin: 0;
  }

  .media-hover({
    background-color: fade(@white, 5%);
  });
}

.text {
  position: sticky;
  top: 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  @media @s-tablet {
    display: block;
  }
}
