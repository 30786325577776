@import 'descriptions';

.loss {
  background-color: @red;
  position: relative;

  @media @s-tablet {
    .radius(4);
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 7px 10px;
  }
}

.desktopTitle {
  letter-spacing: 0.56px;
  line-height: 20px;
  margin-bottom: 16px;

  @media @s-tablet {
    display: none;
  }
}

.mobileTitle {
  display: none;

  @media @s-tablet {
    display: block;
  }
}

.row {
  @media @s-tablet {
    display: none;
  }

  & + & {
    margin-top: 12px;
  }
}

.label {
  opacity: 0.6;
  margin-bottom: 4px;
}

.number {
  line-height: 20px;
}

.buttonRulesShow {
  opacity: 0.6;
  margin-top: 16px;

  @media @s-tablet {
    display: none;
  }
}

.moreDetailsBtn {
  display: none;

  @media @s-tablet {
    display: flex;
  }
}
