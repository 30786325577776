@import 'descriptions';

.rules {
  background: @gold-gradient;
  color: @dark-text-color;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  @media @s-tablet {
    .radius(4);
    padding: 7px 10px;
  }
}

.shortRule {
  @media @s-tablet {
    .Rubik();
  }
}

.desktopBlock {
  display: block;

  @media @s-tablet {
    display: inline;
  }
}

.value {
  @media @s-tablet {
    font-weight: 700;
  }
}

.buttonRulesShow {
  margin-top: 4px;
  margin-inline: auto;

  @media @s-tablet {
    margin: 0;
  }
}
