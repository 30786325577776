@import 'descriptions';

.tableTh,
.tableTd {
  .f-center();
  text-align: center;
  flex: 1;
  padding-inline: 16px;
}

.tableTh {
  color: fade(@white, 50%);
  margin-bottom: 4px;
}

.tableTd {
  background-color: @blocks-bg;
  position: relative;
  padding-block: 12px;

  &:first-child {
    .left-radius(12);
  }

  &:last-child {
    .right-radius(12);
  }

  &:first-child:last-child {
    .radius(12);
  }

  & + & {
    &::before {
      .absY-alignment();
      background-color: fade(@white, 10%);
      content: '';
      left: -0.5px;
      width: 1px;
      height: 20px;
    }
  }
}
