@import 'descriptions';

.gameField {
  .radius();
  background-color: @blocks-bg;
  position: relative;
  padding: 36px;

  @media @tablet {
    padding: 16px;
  }

  @media @s-tablet {
    .radius(4);
    border: 1px solid #2b2434;
    background: repeating-linear-gradient(to right, fade(@gray-blue, 30%) 0, fade(@gray-blue, 30%) 1%, transparent 2%, transparent 100%) ~'0 0 / 10% 100%',
    repeating-linear-gradient(to bottom, fade(@gray-blue, 30%) 0, fade(@gray-blue, 30%) 1%, transparent 2%, transparent 100%) ~'0 0 / 100% 10%';
    padding: 0;
  }

  &.disabled {
    cursor: not-allowed;

    &::after {
      .abs-overlay();
      content: '';
    }
  }
}

.buttons {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 12px;

  @media @tablet {
    gap: 4px;
  }

  @media @s-tablet {
    gap: 0;
  }
}
