@import 'descriptions';
@import 'animations/smooth-show';

.overlay {
  .blur(8, back);
  .f-center();
  .smooth-show(@display: flex);
  .abs-overlay(fixed);
  z-index: @z-index-modal;
  background-color: rgba(12, 11, 20, 0.70);
}
