@import 'descriptions';

.resultDialog {
  text-align: center;
  width: 300px;

  @media @s-tablet {
    .f-column();
    margin-inline: auto;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.content {
  @media @s-tablet {
    margin-top: auto;
  }
}

.icon {
  margin-bottom: 32px;

  @media @s-tablet {
    .square(90);
    margin-bottom: 16px;
  }
}

.btn {
  margin-top: 40px;

  @media @s-tablet {
    margin-top: auto;
  }
}
