@import 'descriptions';

.authTabs {
  display: flex;
  gap: 16px;
}

.link {
  .h3();
  .shadow();
  .f-center();
  .radius(16);
  .hover(@gray-blue, @white);
  text-transform: uppercase;
  font-weight: 700;
  flex: 1;
  height: 64px;

  @media @s-tablet {
    .base-text();
    font-weight: 400;
    text-transform: initial;
    height: 50px;
  }

  &.active {
    color: @white;
    background-color: @addition-bg;
    cursor: default;
  }
}
