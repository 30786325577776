@import 'descriptions';

.balance {
  position: relative;
  overflow: hidden;
  min-height: 128px;
}

.title {
  line-height: 20px;
  margin-bottom: 4px;
}

.balanceInfo {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.value {
  letter-spacing: 0.96px;
  line-height: 24px;
}

.actions {
  .f-between();
  text-transform: uppercase;
  letter-spacing: 0.56px;
  margin-top: 16px;
}

.link {
  .hover(fade(@white, 20%), @white);
  display: flex;
  align-items: center;
  gap: 4px;

  .russian & {
    .xs-text();
  }
}

.btn {
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
}
