@import 'descriptions';

.rubik {
  .Rubik();
}

.uppercase {
  text-transform: uppercase;
}

.xs {
  .xs-text();
}

.s {
  .s-text();
}

.base {
  .base-text();
}

.l {
  .l-text();
}

.xl {
  .xl-text();
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}

.w800 {
  font-weight: 800;
}

.w900 {
  font-weight: 900;
}

.black {
  color: @black;
}

.white {
  color: @white;
}

.red {
  color: @red;
}

.grayBlue {
  color: @gray-blue;
}

.grayBlue2 {
  color: @gray-blue-2;
}
