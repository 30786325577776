@import 'descriptions';
@import 'animations/smooth-show';

.languageSwitcher {
  position: relative;
  width: fit-content;
}

.btn {
  .transition(color);
  display: flex;
  align-items: center;

  .media-hover({
    color: @gray-blue;
  });
}

.current {
  .s-text();
  font-weight: 600;
}

.option {
  width: 100%;
  height: 36px;
  padding-inline: 16px;

  &.active {
    font-weight: 600;
  }
}

.iconGlobe {
  margin-right: 10px;
}

.iconArrowDropdown {
  .transition(transform);

  .opened & {
    .rotate(180);
  }
}

.dropdown {
  .radius(12);
  position: absolute;
  background-color: @black;
  overflow: hidden;
  text-align: left;
  display: none;

  .opened & {
    .smooth-show();
  }

  .top & {
    bottom: 100%;
    margin-bottom: 6px;
  }

  .bottom & {
    top: 100%;
    margin-top: 6px;
  }

  .left & {
    left: 0;
  }

  .right & {
    right: 0;
  }

  .center & {
    .offsetX();
    left: 50%;
  }
}
