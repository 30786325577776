@import 'descriptions';

.innerPageHeader {
  position: relative;
}

.backBtn {
  .absY-alignment();
  color: @gray-blue;
  left: 0;
  display: none;

  @media @s-tablet {
    display: block;
  }
}

.iconArrowBack {
  .flipX();
}

.title {
  line-height: 32px;

  @media @s-tablet {
    text-align: center;
    line-height: 24px;
  }
}
