@import 'descriptions';

.box {
  .radius();
  background-color: @blocks-bg;
  padding: 24px;

  @media @tablet {
    padding: 16px;
  }

  @media @s-tablet {
    .radius(16);
    padding-block: 8px;
  }
}
