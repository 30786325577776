@import 'descriptions';

.pagination {
  display: flex;
  gap: 8px;

  @media @s-tablet {
    gap: 6px;
  }
}

.centered {
  justify-content: center;
}

.btn {
  @media @s-tablet {
    font-size: @xs-font-size;
    padding-inline: 16px;
  }

  .media-hover({
    &:not(.disabled):not(:disabled) {
      color: @white;
      border-color: @primary-color;
    }
  });

  &.active,
  &.dots {
    cursor: default;
  }

  &.active {
    background-color: @primary-color;
    color: @white;
    opacity: 1;
  }

  &:disabled {
    pointer-events: none;
  }
}

.btnContent {
  @media @s-tablet {
    line-height: 30px;
    height: 30px;
    padding: 0;
  }
}

.iconArrowPrev {
  .rotate(-180);
}
