@import 'descriptions';

.personalAccount {
  .f-column();
  gap: 32px;
  min-height: calc(100dvh - @topline-height);
  margin-bottom: -@bottom-padding;

  @media @tablet {
    min-height: calc(100dvh - @tablet-topline-height);
    margin-bottom: -@tablet-bottom-padding;
  }

  @media @s-tablet {
    min-height: 100dvh;
    margin-bottom: -@mobile-bottom-padding;
  }
}

.header {
  .f-between();

  @media @s-tablet {
    margin-bottom: -8px;
  }
}

.switcher {
  z-index: 1;
}

.support {
  margin-top: auto;
}
