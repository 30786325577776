@import 'descriptions';

.title {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.h1 {
  .h1();
}

.h2 {
  .h2();
}

.h3 {
  .h3();
}

.xs {
  .xs-text();
}

.s {
  .s-text();
}

.base {
  .base-text();
}

.l {
  .l-text();
}

.xl {
  .xl-text();
}
