@font-face {
  font-family: Rubik;
  src: url('../assets/fonts/Rubik/Rubik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../assets/fonts/Rubik/Rubik-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../assets/fonts/Rubik/Rubik-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../assets/fonts/Rubik/Rubik-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../assets/fonts/Rubik/Rubik-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../assets/fonts/Rubik/Rubik-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: PTRoot;
  src: url('../assets/fonts/PTRoot/PTRoot-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: PTRoot;
  src: url('../assets/fonts/PTRoot/PTRoot-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: PTRoot;
  src: url('../assets/fonts/PTRoot/PTRoot-DemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: PTRoot;
  src: url('../assets/fonts/PTRoot/PTRoot-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: PTRoot;
  src: url('../assets/fonts/PTRoot/PTRoot-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Rubik;
  src: url('../assets/fonts/Rubik/Rubik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
