@import 'descriptions';

.spinnerContainer {
  display: flex;
}

.info {
  margin-bottom: 12px;

  @media @s-tablet {
    margin-bottom: 24px;
  }
}

.profileText {
  margin-bottom: 4px;

  @media @s-tablet {
    opacity: 0.5;
  }
}

.profileLink {
  .radius(8);
  .f-between();
  .transition(background-color);
  color: @white;
  padding: 4px;
  margin-inline: -4px;

  .media-hover({
    background-color: fade(@white, 5%);
  });
}

.iconArrowBack {
  .flipX();
  .transition(color);
  color: @gray-blue;

  @media @s-tablet {
    display: none;
  }

  .media-support-hover({
    .profileLink:hover & {
      color: @white;
    }
  });
}

.iconArrowRight {
  color: @gray-blue;
  display: none;

  @media @s-tablet {
    display: block;
  }
}

.mainContent {
  .radius();
  .shadow();
  background: @addition-bg-gradient;
  text-align: center;
  padding: 24px;
}

.balance {
  .f-center();
  gap: 10px;
  line-height: 32px;
  margin-bottom: 4px;
}

.iconWallet {
  color: @gray-blue;
}

.chips {
  margin-bottom: 4px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
}

.moneyText {
  @media @s-tablet {
    display: none;
  }
}

.cashierBtn {
  font-weight: 700;
}
