@import 'descriptions';

.header {
  .f-between();
  margin-bottom: 16px;

  @media @s-tablet {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 12px;
  }
}

.successText {
  .linear-gradient-text(@gold-gradient);
  text-align: center;
  line-height: 20px;
  margin-bottom: 16px;

  @media @s-tablet {
    margin-bottom: 12px;
  }
}

.description {
  text-align: center;
  max-width: 590px;
  margin-inline: auto;
  padding-top: 16px;
  margin-bottom: 16px;
}

.form {
  .f-column();
  gap: 16px;
  margin-bottom: 32px;

  @media @s-tablet {
    gap: 12px;
    margin-bottom: 24px;
  }
}

.currency {
  display: flex;
  align-items: center;
  gap: 4px;
}
