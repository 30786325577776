@import 'descriptions';
@import 'animations/smooth-show';

.currencyDropdown {
  position: relative;
  z-index: 1;
  width: fit-content;

  @media @s-tablet {
    width: 100%;
  }
}

.btn {
  .transition(color);
  font-weight: 600;
  align-items: center;
  gap: 8px;
  min-width: 136px;
  padding-inline: 16px;

  @media @s-tablet {
    width: 100%;
  }

  .media-hover({
    color: @gray-blue;
  });
}

.current {
  .radius();
  background-color: @black;
  display: inline-flex;
  height: @buttons-height;

  .opened & {
    @media @s-tablet {
      .top-radius();
    }
  }
}

.iconArrowAccordion {
  .transition(transform);
  .rotate(180);
  margin-left: 12px;

  @media @s-tablet {
    margin-left: auto;
  }

  .opened & {
    .rotate(0);
  }
}

.dropdown {
  .radius();
  .absX-alignment();
  background-color: @black;
  text-align: left;
  top: 100%;
  padding-bottom: 8px;
  margin-top: 5px;
  display: none;

  @media @s-tablet {
    border-top: 1px solid fade(@white, 10%);
    position: static;
    transform: none;
    margin-top: 0;
  }

  .opened & {
    .smooth-show();

    @media @s-tablet {
      .bottom-radius();
    }
  }
}

.option {
  display: flex;
  height: 44px;
}
