@import 'descriptions';

.spinnerContainer {
  display: flex;
}

.th {
  &:last-child {
    padding-right: 45px;

    @media @s-tablet {
      padding-right: 30px;
    }
  }
}

.amount {
  gap: 4px;
}

.iconArrowTransactions {
  margin-right: 8px;

  &.down {
    .flipY();
    color: @gray-blue-2;
  }
}

.status {
  display: block;

  &:first-letter {
    text-transform: capitalize;
  }
}

.created {
  color: @orange;
}

.completed {
  color: @light-green;
}

.rejected,
.cancelled {
  color: @red-light;
}

.wallet {
  color: @primary-color;
  padding-right: 45px;

  @media @s-tablet {
    padding-right: 30px;
  }
}

.cancelBtn {
  .right-radius(12);
  .hover(@secondary-text-color, @primary-hover);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 45px;

  @media @s-tablet {
    width: 30px;
  }
}

.pagination {
  padding-top: 16px;
}
