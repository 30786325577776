@import 'descriptions';

.logo {
  width: 302px;
  height: 55px;

  @media @tablet {
    width: 77px;
  }
}

.small {
  width: 77px;
}

.link {
  .transition(transform);
  display: block;
  width: fit-content;

  .media-hover({
    .scale(1.03);
  });
}

