@import 'descriptions';

.box {
  color: @dark-text-color;
  background: @gold-gradient;
  position: relative;

  @media @s-tablet {
    .radius(4);
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
  }
}

.desktopTitle {
  line-height: 24px;
  letter-spacing: 0.8px;
  margin-bottom: 12px;

  @media @s-tablet {
    display: none;
  }
}

.mobileTitle {
  letter-spacing: 0.02em;
  display: none;

  @media @s-tablet {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.imgMobile {
  height: 23px;
}

.row {
  @media @s-tablet {
    display: none;
  }
}

.buttonRulesShow {
  margin-top: 12px;

  @media @s-tablet {
    display: none;
  }
}

.moreDetailsBtn {
  display: none;

  @media @s-tablet {
    display: flex;
  }
}

.img {
  .square(160);
  position: absolute;
  top: -4px;
  right: -56px;

  @media @tablet {
    .square(120);
    top: 36px;
    right: -36px;
  }

  @media @s-tablet {
    display: none;
  }
}
