@import 'descriptions';

.randomButton {
  padding: 0;
  min-width: @buttons-height;
}

.iconDice {
  .offsetX(-0.05em);
  display: none;

  @media @s-tablet {
    display: block;
  }
}

.text {
  @media @s-tablet {
    display: none;
  }
}
