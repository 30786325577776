@import 'descriptions';

.infoMessage {
  .radius();
  background-color: @blocks-bg;
  display: flex;
  gap: 16px;
  line-height: 24px;
  padding: 24px;

  @media @s-tablet {
    padding: 16px;
  }
}

.text {
  line-height: 24px;
}
