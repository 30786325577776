@import 'descriptions';

.title {
  line-height: 28px;
  margin-bottom: 16px;
}

.buttons {
  .f-column();
  gap: 8px;
  margin-top: 24px;
}
