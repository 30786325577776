@import 'descriptions';

.topline {
  display: flex;
  align-items: center;
  height: @topline-height;
  padding-inline: @desktop-padding;

  @media @tablet {
    height: @tablet-topline-height;
  }

  @media @s-tablet {
    display: none;
  }
}

.devLangSwitcher {
  position: relative;
  z-index: @z-index-popover;
  margin-left: auto;
}

.buttonFinishDemoGame {
  margin-left: auto;

  .devLangSwitcher + & {
    margin-left: 24px;
  }
}
