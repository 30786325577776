@import 'descriptions';

.instruction {
  .radius(22);
  background-color: @background-color;
  padding: 14px 27px;

  @media @s-tablet {
    padding-inline: 16px;
  }
}

.title {
  margin-bottom: 10px;
}

.list {
  list-style-type: decimal;
  text-align: left;
  padding-left: 0.7em;
  margin-bottom: 10px;
}

.text {
  line-height: 20px;
}
