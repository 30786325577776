@import 'descriptions';

.paymentDetainsButtons {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 32px;

  @media @s-tablet {
    gap: 10px;
    flex-direction: column;
    margin-top: 24px;
  }
}
