@import 'descriptions';

.game {
  display: flex;
  gap: 16px;
}

.content {
  flex: 1;
  min-width: 0;
}

.header {
  margin-bottom: 16px;

  @media @s-tablet {
    margin-inline: -@mobile-padding;
  }
}

.result {
  display: none;

  @media @s-tablet {
    display: flex;
  }
}

.win {
  margin-bottom: 0;
}

.loss {
  margin-bottom: 16px;
}

.rules {
  margin-bottom: 16px;
  display: none;

  @media @s-tablet {
    display: flex;
  }
}

.control {
  .radius(0);
  background-color: transparent;
  flex-direction: column-reverse;
  gap: 12px;
  padding: 0;
  margin-top: 8px;
  display: none;

  @media @s-tablet {
    .top-radius();
    background-color: @background-color;
    display: flex;
    position: sticky;
    bottom: 0;
    padding-block: 16px 20px;
  }
}

.sidebar {
  width: 323px;

  @media @tablet {
    width: 285px;
  }

  @media @s-tablet {
    display: none;
  }
}

.sidebarContent {
  position: sticky;
  top: 16px;
}
