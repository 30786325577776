@import 'descriptions';

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
article,
aside,
canvas,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
}

address,
caption,
th {
  font-style: normal;
  font-weight: normal;
}

fieldset,
img,
button {
  border: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  text-align: left;
}

sup {
  font-size: 0.7em;
  vertical-align: super;
  line-height: 0;
}

svg {
  vertical-align: middle;
}

input {
  .PTRoot();

  &[type='text'] {
    -webkit-appearance: none;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-clear-button,
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

textarea {
  -webkit-appearance: none;
}

button {
  .button-reset();
}

img {
  display: block;
  word-break: break-all;
  max-width: 100%;
}

b, strong {
  font-weight: 500;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
  }
}

::-ms-clear {
  display: none;
}
