@import 'descriptions';

.pseudoLink {
  .transition(opacity);
  opacity: 0.5;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;

  .media-hover({
    opacity: 1;
  });
}

.pseudoLinkText {
  line-height: 1;
  border-bottom: 1px dashed;
}
