@import 'descriptions';

.title {
  margin-bottom: 8px;
}

.form {
  .f-column();
  gap: 12px;
  margin-top: 20px;
}

.recoveryRow {
  display: flex;
  justify-content: flex-end;
}

.buttons {
  .f-column();
  gap: 8px;
  margin-top: 32px;
}
