@import 'descriptions';

.header {
  margin-bottom: 24px;
}

.tabs {
  margin-bottom: 16px;

  @media @s-tablet {
    margin-bottom: 24px;
  }
}

.content {
  .radius(32);
  position: relative;
  padding: 32px;

  @media @s-tablet {
    .radius(16);
    padding: 16px;
  }
}
