@import 'descriptions';

.walletAddress {
  .radius();
  overflow: hidden;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 20px;
  line-height: 20px;
  padding: 16px;

  @media @s-tablet {
    flex-direction: column-reverse;
  }

  &::before {
    .absX-alignment();
    background: @addition-bg-gradient;
    content: '';
    animation: gradient 8s linear infinite;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 200%;
  }
}

.imgContainer {
  .radius(8);
  .square(104);
  background-color: #fefefe;
  overflow: hidden;
  flex: none;
}

.content {
  min-width: 0;
  max-width: 100%;
}

.title {
  display: block;
  margin-bottom: 4px;
}

.addressContainer {
  .f-between();
  font-weight: 600;
  line-height: 24px;
  gap: 10px;
  margin-bottom: 16px;

  @media @s-tablet {
    margin-bottom: 12px;
  }
}

.address {
  .ellipsis();
  display: block;
  min-width: 0;
  flex: 1;
}

.copyBtn {
  .media-hover({
    .transition(color);
    color: @gray-blue;
  });
}

.text {
  padding-right: 30px;

  @media @s-tablet {
    padding-right: 0;
  }
}

@keyframes gradient {
  from {
    transform: translateX(-50%);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-75%);
  }

  to {
    transform: translateX(-50%);
  }
}
