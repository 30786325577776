@import 'descriptions';

.label {
  margin-bottom: 12px;
}

.requisites {
  .radius(12);
  border: 2px solid @primary-color;
  background-color: @addition-bg;
  position: relative;
  padding: 16px 38px 16px 18px;
}

.title {
  font-size: 21px;
  line-height: 20px;
  margin-bottom: 8px;
}

.number {
  .linear-gradient-text(linear-gradient(196.47deg, #d5af4a -0.4%, #eae17b 45.62%, #d9ce57 72.56%, #d5b14b 97.97%));

  @media @s-tablet {
    display: block;
    margin-top: 8px;
  }
}

.text {
  line-height: 20px;

  & + & {
    margin-top: 8px;
  }
}

.copyBtn {
  .absY-alignment();
  right: 16px;
}
