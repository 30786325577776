@import 'descriptions';

.header {
  margin-bottom: 24px;
}

.form,
.row {
  .f-column();
}

.form {
  gap: 24px;
  margin-top: 24px;
}

.row {
  gap: 16px;
}
