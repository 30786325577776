@import 'descriptions';

.PTRoot() {
  font-family: PTRoot, Arial, sans-serif;
}

.Rubik() {
  font-family: Rubik, PTRoot, Arial, sans-serif;
}

.h1() {
  font-size: @font-size-h1;
  line-height: 36px;
}

.h2() {
  font-size: @font-size-h2;
  line-height: 32px;

  @media @s-tablet {
    .h3();
  }
}

.h3() {
  font-size: @font-size-h3;
  line-height: 28px;
}

.base-text() {
  font-size: @font-size;
  line-height: 24px;
}

.xs-text() {
  font-size: @xs-font-size;
  line-height: 16px;
}

.s-text() {
  font-size: @s-font-size;
  line-height: 16px;
}

.l-text() {
  font-size: @l-font-size;
  line-height: 20px;
}

.xl-text() {
  font-size: @font-size-h3;
  line-height: 20px;
}
