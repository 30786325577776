@import 'descriptions';

.buttonPaid {
  .radius(48);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 50px;
}

.progress {
  .abs-overlay();
  .f-center();
  cursor: not-allowed;

  &.done {
    pointer-events: none;
  }
}

.progressBar {
  .transition(width);
  background-color: @primary-disabled;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.progressContent {
  position: relative;
  z-index: 1;
}
