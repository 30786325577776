@import 'descriptions';

.infoBox {
  .radius(12);
  border: 1px solid @gray-blue-2;
  color: fade(@white, 30%);
  height: @fields-height;
  display: flex;
  align-items: center;
  padding-inline: 16px;

  @media @s-tablet {
    height: 52px;
  }
}

.tooltip {
  margin-left: 4px;
}

.content {
  margin-left: auto;
}
