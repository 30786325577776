@import 'descriptions';

.topline {
  margin-bottom: 17px;

  @media @tablet {
    margin-bottom: 12px;
  }
}

.main {
  padding-bottom: @bottom-padding;

  @media @tablet {
    padding-bottom: @tablet-bottom-padding;
  }

  @media @s-tablet {
    padding-bottom: @mobile-bottom-padding;
  }
}
