@import 'descriptions';

.formButtons {
  display: flex;
  gap: 16px;
  margin-top: 52px;

  @media @s-tablet {
    gap: 10px;
    padding-top: 36px;
    margin-top: auto;
  }
}

.cancelBtn {
  max-width: 154px;
}
