@import 'descriptions';

.error {
  .f-column();
  justify-content: center;
  text-align: center;
  height: calc(100dvh - @topline-height);
  margin-bottom: -@bottom-padding;

  @media @tablet {
    height: calc(100dvh - @tablet-topline-height);
    margin-bottom: -@tablet-bottom-padding;
  }

  @media @s-tablet {
    height: 100dvh;
    margin-bottom: -@mobile-bottom-padding;
  }
}

.title {
  margin-bottom: 16px;

  @media @s-tablet {
    margin-bottom: 12px;
  }
}

.btn {
  max-width: 400px;
  margin-top: 32px;
  margin-inline: auto;
}
