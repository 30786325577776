@import 'descriptions';

.inputPassword {
  position: relative;
}

.field {
  padding-right: 16px * 2 + 24px;
}

.toggleVisibilityBtn {
  .hover(@gray-blue, @white);
  position: absolute;
  top: 0;
  right: 0;
  height: @fields-height;
  padding-inline: 16px;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.iconContainer {
  position: relative;
}

.icon {
  .transition(opacity);
}

.eye {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  .visible & {
    opacity: 1;
  }
}

.eyeCrossed {
  .visible & {
    opacity: 0;
  }
}
