@import 'descriptions';

.tabs {
  .radius(30);
  background-color: @blocks-bg;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  @media @s-tablet {
    .radius(16);
    .s-text();
  }
}

.tab {
  .radius();
  .f-center(inline-flex);
  .transition(color);
  .transition(background-color);
  color: @gray-blue;
  font-weight: 500;
  text-transform: uppercase;
  flex: 1;
  height: 44px;

  @media @s-tablet {
    .radius(12);
    text-transform: initial;
  }

  .media-hover({
    background-color: fade(@white, 5%);
    color: @white;
  });

  &.active {
    font-weight: 800;
    background-color: @primary-color;
    color: @white;

    @media @s-tablet {
      font-weight: 700;
    }
  }
}
