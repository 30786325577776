@import 'descriptions';

.startGameDialog {
  text-align: center;
  width: 100vw;
  max-width: 320px;

  @media @s-tablet {
    .f-column();
    align-items: center;
    width: auto;
    max-width: initial;
    height: 100%;
  }
}

.content {
  @media @s-tablet {
    margin-block: auto;
  }
}

.logo {
  width: 108px;
  height: 78px;
  margin-bottom: 24px;
}

.title {
  margin-bottom: 24px;
}

.buttons {
  .f-column();
  gap: 16px;
  margin-top: 40px;

  @media @s-tablet {
    width: 100%;
  }
}

.languageSwitcher {
  margin-top: 16px;
  margin-inline: auto;
}
