@import 'descriptions';

.wrap {
  max-width: @layout-width;
  padding-inline: @desktop-padding;
  margin-inline: auto;

  @media @s-tablet {
    padding-inline: @mobile-padding;
  }
}
