@import 'descriptions';

.gameButton {
  .l-text();
  .radius(8);
  .aspect-ratio(1, 1);
  .transition(color);
  .transition(box-shadow);
  .transition(border-color);
  .transition(background-color);
  color: @text-color;
  background-color: @addition-bg;
  border: 1px solid transparent;
  font-weight: 600;
  letter-spacing: 0.72px;
  width: 100%;
  max-width: @buttons-height;
  height: auto;
  max-height: @buttons-height;

  @media @s-tablet {
    .radius(4);
    font-size: @s-font-size;
    letter-spacing: 0.56px;
    background-color: transparent;
    max-width: none;
    max-height: none;
  }

  .media-hover({
    background-color: @primary-hover;
  });

  .media-support-hover({
    &:active {
      background-color: @primary-press;
    }
  });

  &:disabled {
    background-color: fade(@gray-blue, 20%);
    cursor: not-allowed;
  }

  &.active {
    .shadow();
    border-color: @white;
    background: @addition-bg-gradient;
  }

  &.win {
    color: @dark-text-color;
    background: @gold-gradient;
    border: 4px solid @gold-stroke;
    font-weight: 800;
  }

  &.loss {
    background-color: @red;
  }
}
